import {
  EventDetailsImageRatio,
  EventDetailsButtonStyle,
  EventDetailsImagePosition,
  EventDetailsAlignment,
  HEADER_LAYOUT,
  HEADER_IMAGE_ALIGNMENT,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import type {PageComponentSettings} from './stylesParams'
import {PageComponentTextSettings} from './settingsParams'

// for params that may come from server
export const defaultStyleParams = (rtl: boolean): Partial<PageComponentSettings> => ({
  imageRatio: EventDetailsImageRatio['16:9'],
  buttonStyle: EventDetailsButtonStyle.FULL,
  formButtonStyle: EventDetailsButtonStyle.FULL,
  imagePosition: EventDetailsImagePosition.CENTER,
  headerAlignment: EventDetailsAlignment.CENTER,
  contentAlignment: rtl ? EventDetailsAlignment.RIGHT : EventDetailsAlignment.LEFT,
  imageOpacity: 100,
  socialShareVisible: true,
  aboutSectionVisible: true,
  scheduleVisible: true,
  groupVisible: true,
  mapVisible: true,
  summaryBoxVisible: true,
  rsvpButtonVisible: true,
  readMoreButtonVisible: true,
  membersVisible: true,
  membershipsVisible: true,
  headerLayout: HEADER_LAYOUT.CLASSIC,
  headerImageAlignment: HEADER_IMAGE_ALIGNMENT.LEFT,
  headerBorderWidth: 0,
})

export const defaultTexts = (
  settings: PageComponentSettings,
  t: TFunction,
  responsive = false,
): Partial<PageComponentTextSettings> => ({
  guestsTitleText: t('guestsTitleText'),
  rsvpButtonText: responsive ? t('listTicketsButtonText') : t('listRSVPButtonText'),
  timeAndLocationTitleText: t('timeAndLocationTitleText'),
  aboutTitleText: t('aboutEventTitleText'),
  scheduleTitleText: t('scheduleTitleText'),
  ticketsTitleText: t('ticketsSectionTitleText'),
  readMoreText: t('readMoreText'),
  readLessText: t('readLessText'),
  shareTitleText: t('shareTitleText'),
  ...{
    rsvpEventButtonText: settings?.texts?.rsvpButtonText ?? t('listRSVPButtonText'),
    ticketedEventButtonText: settings?.texts?.rsvpButtonText ?? t('listTicketsButtonText'),
  },
})

export const fillSettingsDefaults = ({
  settings,
  rtl,
  responsive,
  t,
}: {
  settings: PageComponentSettings
  rtl: boolean
  responsive: boolean
  t: TFunction
}): PageComponentSettings => ({
  ...defaultStyleParams(rtl),
  ...(settings ?? ({} as PageComponentSettings)),
  texts: {
    ...defaultTexts(settings, t as TFunction, responsive),
    ...(settings?.texts ?? {}),
  } as PageComponentTextSettings,
})
